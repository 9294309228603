<template>

  <b-form-group
      :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
      :label="displayLabel?capitalize($t(name)):''"
      :key="componentKey"
  >
    <validation-provider
        v-if="isRequired"
        #default="{ errors }"
        :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
        rules="required"
    >
      <flat-pickr
          v-model="localModel"
          class="form-control w-100"
          :config="config"
          :disabled="disabled"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>


    <flat-pickr
        v-else
        v-model="localModel"
        class="form-control w-100"
        :config="config"
        :disabled="disabled"
        ref="flatPickr"
    />
  </b-form-group>

</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { required } from '@/utils/validations/validations'
import { capitalize } from '@/utils/filter'
import { French as FrenchLocale } from 'flatpickr/dist/l10n/fr.js'
import { English as EnglishLocale } from 'flatpickr/dist/l10n/default.js'
import { ValidationProvider } from 'vee-validate'

import flatPickr from 'vue-flatpickr-component'
import i18n from '@/libs/i18n'

export default {
  components: {
    flatPickr,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    enableTime: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    onlyMonth: {
      type: Boolean,
      default: false
    },
    minDate: {},
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const config = ref({
      locale: i18n.locale=='fr'?FrenchLocale:EnglishLocale,
      altInput: true,
      // altFormat: dateFormat.value,
      enableTime: props.enableTime,
      allowInput: true,
      static: true,
      inline: props.inline
    })
    const componentKey = ref(0)
    const flatPickr= ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const dateFormat = computed(() => {
      if (i18n.locale == 'fr') {
        if (props.enableTime == true) {
          return 'd/m/Y H:i'
        } else if (props.onlyMonth == true) {
          return 'F Y'
        } else {
          return 'd/m/Y'
        }
      } else {
        if (props.enableTime == true) {
          return 'Y-m-d H:i'
        } else if (props.onlyMonth == true) {
          return 'F Y'
        } else {
          return 'Y-m-d'
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted( () => {
      if (props.inline) {
        flatPickr.value.$parent.$el.children[0].children[0].children[1].className += " hidden"
      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      required,
      capitalize,
      FrenchLocale,
      EnglishLocale,

      // Data
      localModel,
      config,
      componentKey,
      flatPickr,

      // Computed
      dateFormat,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    minDate: function(val) {
      this.$set(this.config, "minDate", val);
    },
    isRequired: function (val) {
      this.componentKey++
    }
  },
  methods: {
  },
  mounted () {
    this.$set(this.config, "altFormat", this.dateFormat);
    this.$set(this.config, "minDate", this.minDate);
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/vue/libs/vue-flatpicker';
</style>